import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
// import AddButton from 'components/store/common/AddButton';
import ProductColors from 'components/store/common/ProductColors';
import { getDiscount, getProductImage } from 'components/store/utils';
import { Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { useLanguageLoader } from 'contexts/language';
import { useIsCustomDomain } from 'contexts/profile';
import ProductTags from 'components/store/common/ProductTags';
import styles from './ProductCard.module.css';
import GetPrice from '../common/GetPrice';

export function ProductCard({
  shop,
  product
}) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  const img = getProductImage(product);

  const discount = getDiscount(product);

  const lang = useLanguageLoader();
  const isOOS = product?.status === 'out of stock';

  const onClick = (_, value) => {
    let url = isCustomDomain ? `/${product.slug}` : `/${product.shopSlug}/${product.slug}`;
    if(!window.isWebView) {
      if (value) {
        url = url + `?color=${value}`;
      }
      window.open(url,'_blank');
    }
    else
      router.push(url);
  };

  return (
    <Grid item xs={6} md={3}>
      <div className={styles.container}>
        <Clickable className={styles.click} onClick={onClick}>
          <div className={styles.imageContainer}>
            <img loading={"lazy"} className={styles.img} src={img} alt="" />
            {isOOS && (
              <span className={styles.oos}>{lang('buttons.soldOut1')}</span>
            )}
          </div>
          <ProductColors product={product} onClick={onClick} />
          <div className={styles.name}>
            {product.title}
          </div>
          <div className={styles.price}>
            <div className={styles.amount}>
              <span>
                {shop.currency}
              </span>
              {' '}
              <GetPrice price={product.discountedAmount} />
            </div>
            <div className={styles.unit}>
              {/* Per*/}
              {/* {' '}*/}
              {product.unit.value === 1 ? '' : product.unit.value}
              {' '}
              {product.unit.type}
            </div>
          </div>
          <div className={styles.price2}>
            {discount > 0 && (
              <>
                <div className={styles.originalAmount}>
                  {shop.currency}
                  {' '}
                  {product.amount}
                </div>
                <div className={styles.discount}>
                  {discount}
                  % OFF
                </div>
              </>
            )}
          </div>
        </Clickable>
        {/* <AddButton shop={shop} product={product} /> */}
        <ProductTags product={product} shop={shop} />
      </div>
    </Grid>
  );

}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};

ProductCard.defaultProps = {};

export default ProductCard;
