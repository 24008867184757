import { defaultProps, props } from 'containers/themes/props/store';
import { TopBar } from 'components/store/TopBar';
import { getUserIcon } from 'components/utils/icon';
import { Grid } from '@mui/material';
import StoreDescription from 'components/store/StoreDescription';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import React from 'react';
import NoOrders from 'components/home/NoOrders';
import { useDeviceWidth } from 'hooks/removeJss';
import Catalogs from 'components/store/calathea/Catalogs';
import Templates from 'components/store/calathea/Templates';
import ViewAll from 'components/store/common/ViewAll';
import { useLanguageLoader } from 'contexts/language';
import { ProductCard } from 'components/store/calathea/ProductCard';
import { useIsDesktop } from 'contexts/profile';
import { useInfiniteShopProducts } from 'hooks/product';
import { Container } from 'components/layout/Container';
import styles from './store.module.css';
import ShopUtils from 'ui/lib/shopUtils';
import ViewAllCatalogLazy from 'components/store/common/ViewAllCatalogLazy';
const emptyProducts = '/images/store/noProducts.svg';
import DisablePopup from 'components/store/common/DisablePopup';


function CalatheaStore({
  shop,
  savedFeed,
  templates,
  catalogList,
}) {
  const width = useDeviceWidth();

  const shopIcon = getUserIcon(shop);
  const lang = useLanguageLoader();
  const isDesktop = useIsDesktop();
  const [products] = useInfiniteShopProducts(savedFeed);
  const showTemplate = ShopUtils.showBanner(shop);
  const shopProfileEnabled = ShopUtils.isSectionEnabled(shop, 'shopProfile');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const featuredProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');
  const productsEnabled = ShopUtils.isSectionEnabled(shop, 'products');

  const feedTitle = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedTitle = lang('store.headings.featuredProducts') === feedTitle ?
    lang('store.headings.featuredProducts') : feedTitle;
  const layoutConfig = shop?.uiConfig?.layoutConfig;
  const templateIdShopProfile = layoutConfig?.shopProfile?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundShopProfileTemplates = templates?.filter(template => templateIdShopProfile.includes(template._id));
  const templateIdCollections = layoutConfig?.collections?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundCollectionsTemplates = templates?.filter(template => templateIdCollections.includes(template._id));

  const templateIdFeaturedProducts = layoutConfig?.featuredProducts?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundFeaturedProductsTemplates =
      templates?.filter(template => templateIdFeaturedProducts.includes(template._id));

  const templateIdProducts = layoutConfig?.products?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundProductsTemplates = templates?.filter(template => templateIdProducts.includes(template._id));

  return (
    <div>
      <DisablePopup blocked={shop?.blocked} />
      <TopBar shop={shop} catalogs={catalogList} showTabs={false} />
      <div id="shopScroll1" />
      {shopProfileEnabled && (
        <>
          <div className="mobile">
            <div className={styles.shopIcon}>
              <img className={styles.icon} src={shopIcon} alt="" />
              <div className={styles.shopName}>{shop?.name}</div>
              <StoreDescription lines={3} shop={shop} />
            </div>
          </div>
          <div className="desktop">
            <div className={styles.shopIcon}>
              <Container className={styles.shopIconContainer}>
                <img className={styles.icon} src={shopIcon} alt="" />
                <Grid container spacing={2} className={styles.shopIconGrid}>
                  <Grid item xs={12} className={styles.shopName}>{shop?.name}</Grid>
                  <Grid item xs={12}>
                    <StoreDescription shop={shop} />
                  </Grid>
                  <Grid item xs={12}>
                    <FollowUsAndMore shop={shop} variant="minimal" />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </>
      )}
      {showTemplate && <Templates shop={shop} templates={foundShopProfileTemplates} width={width}/>}
      {collectionsEnabled && (
        <Catalogs shop={shop} catalogs={catalogList} width={width}/>
      )}
      {showTemplate && <Templates templates={foundCollectionsTemplates} shop={shop}/>}
      {featuredProductsEnabled && (
        <ViewAll
          shop={shop}
          products={savedFeed}
          type="featured"
          name={savedTitle}
          label={`${lang('common.browse')} ${savedTitle}`}
        >
          {savedFeed.slice(0, isDesktop ? 8 : 6)
            .map(product => (
              <ProductCard key={product._id} shop={shop} product={product} />
            ))}
        </ViewAll>
      )}
      {products.length === 0 && (
        <div className={styles.overflow1}>
          <NoOrders
            title="Shop does not have any products yet!"
            icon={emptyProducts}
          />
        </div>
      )}
      {showTemplate && <Templates templates={foundFeaturedProductsTemplates} shop={shop}/>}
      {productsEnabled && (
        <>
          {catalogList.map(catalog => (
            <ViewAllCatalogLazy
              height={600}
              key={catalog.value}
              shop={shop}
              name={catalog.value}
              component={ProductCard}
              size={isDesktop ? 8 : 6}
            />
          ))}
        </>
      )}
      {showTemplate && <Templates templates={foundProductsTemplates} shop={shop}/>}
    </div>
  );
}

CalatheaStore.propTypes = props;
CalatheaStore.defaultProps = defaultProps;

export default CalatheaStore;
